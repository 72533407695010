import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { ShopDetailsContext } from "../../Context";

function RigthDiv({ shopStats }) {
  const { shopDetails } = useContext(ShopDetailsContext);

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  });

  return (
    <div className="dashboardRightDiv">
      <div
        className="flexColumn"
        style={{ height: "340px", justifyContent: "space-between" }}
      >
        <div className="dashboardRightDivCard">
          <h1>UPI</h1>
          <span>{shopDetails.shop_upi}</span>
        </div>
        <div className="dashboardRightDivCard">
          <h1>
            Earnings -{" "}
            {new Intl.DateTimeFormat("en-US", { month: "long" }).format(
              new Date()
            )}
          </h1>
          <p>{formatter.format(shopStats.monthly_total)}</p>
        </div>
        <div className="dashboardRightDivCard">
          <h1>
            Orders -{" "}
            {new Intl.DateTimeFormat("en-US", { month: "long" }).format(
              new Date()
            )}
          </h1>
          <p>{shopStats.monthly_count}</p>
        </div>
      </div>
      {/* <div
        className="dashboardRightDivCard"
        style={{ overflowY: "scroll", height: "340px" }}
      >
        <h1>
          Earnings -{" "}
          {new Intl.DateTimeFormat("en-US", { month: "long" }).format(
            new Date()
          )}
        </h1>
        <p style={{ borderBottom: "2px solid black", marginBottom: "20px" }}>
          60
        </p>
        <h1>Earnings - January</h1>
        <p>70</p>
        <h1>Earnings - January</h1>
        <p>70</p>
        <h1>Earnings - January</h1>
        <p>70</p>
        <h1>Earnings - January</h1>
        <p>70</p>
        <h1>Earnings - January</h1>
        <p>70</p>
      </div> */}
    </div>
  );
}

export default RigthDiv;
