import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import locaroLogo from "./assets/Locaro-logo.png";

import "./styles.css";
import GSTIN from "./GSTIN";
import Details from "./Details";
import Address from "./Address";
import { useRef } from "react";

function RegisterPage() {
  const [error, setError] = useState();
  const [completedSteps, setCompletedSteps] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentSubmitData, setCurrentSubmitData] = useState({});
  const history = useHistory();
  const tab1 = useRef();
  const tab2 = useRef();
  const tab3 = useRef();

  useEffect(() => {
    if (currentStep === 1) {
      tab1.current.style.borderBottom = "4px solid white";
      tab2.current.style.borderBottom = "0";
      tab3.current.style.borderBottom = "0";
    } else if (currentStep === 2) {
      tab2.current.style.borderBottom = "4px solid white";
      tab1.current.style.borderBottom = "0";
      tab3.current.style.borderBottom = "0";
    } else if (currentStep === 3) {
      tab3.current.style.borderBottom = "4px solid white";
      tab2.current.style.borderBottom = "0";
      tab1.current.style.borderBottom = "0";
    }
  }, [currentStep]);

  useEffect(() => {
    if (completedSteps === 0) {
      tab1.current.classList.add("completed");
    } else if (completedSteps === 1) {
      tab2.current.classList.add("completed");
    } else if (completedSteps === 2) {
      tab3.current.classList.add("completed");
    }
  }, [completedSteps]);

  return (
    <>
      <div className="businessRegisterPageContainer flex">
        <div className="businessRegisterPageLeftDiv flexColumn flexAlignCenter">
          <img src={locaroLogo} alt="" />
          <h1>Registration</h1>
          <h2>
            Start growing your business <br /> with locaro
          </h2>
          <div>
            <p>
              Already have a <br /> business account?
            </p>
            <button
              onClick={() => {
                history.push("/business/login");
              }}
            >
              Login
            </button>
          </div>
        </div>
        <div className="businessRegisterPageRightDiv">
          <div className="businessRegisterPageTabsContainer flex flexJustifyCenter">
            <div
              className="businessRegisterPageTabs flexColumn flexJustifyAndAlignCenter"
              onClick={() => {
                if (completedSteps >= 0) {
                  setCurrentStep(1);
                }
              }}
              ref={tab1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                viewBox="0 0 24 24"
              >
                <g>
                  <rect fill="none" height="24" width="24" />
                </g>
                <g>
                  <path d="M20,10V8h-4V4h-2v4h-4V4H8v4H4v2h4v4H4v2h4v4h2v-4h4v4h2v-4h4v-2h-4v-4H20z M14,14h-4v-4h4V14z" />
                </g>
              </svg>
              <p>GSTIN / UPI</p>
            </div>
            <div
              className="businessRegisterPageTabs flexColumn flexJustifyAndAlignCenter"
              onClick={() => {
                if (completedSteps >= 1) {
                  setCurrentStep(2);
                }
              }}
              ref={tab2}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                viewBox="0 0 24 24"
              >
                <g>
                  <rect fill="none" height="24" width="24" />
                </g>
                <g>
                  <g />
                  <g>
                    <path d="M21.9,8.89l-1.05-4.37c-0.22-0.9-1-1.52-1.91-1.52H5.05C4.15,3,3.36,3.63,3.15,4.52L2.1,8.89 c-0.24,1.02-0.02,2.06,0.62,2.88C2.8,11.88,2.91,11.96,3,12.06V19c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-6.94 c0.09-0.09,0.2-0.18,0.28-0.28C21.92,10.96,22.15,9.91,21.9,8.89z M18.91,4.99l1.05,4.37c0.1,0.42,0.01,0.84-0.25,1.17 C19.57,10.71,19.27,11,18.77,11c-0.61,0-1.14-0.49-1.21-1.14L16.98,5L18.91,4.99z M13,5h1.96l0.54,4.52 c0.05,0.39-0.07,0.78-0.33,1.07C14.95,10.85,14.63,11,14.22,11C13.55,11,13,10.41,13,9.69V5z M8.49,9.52L9.04,5H11v4.69 C11,10.41,10.45,11,9.71,11c-0.34,0-0.65-0.15-0.89-0.41C8.57,10.3,8.45,9.91,8.49,9.52z M4.04,9.36L5.05,5h1.97L6.44,9.86 C6.36,10.51,5.84,11,5.23,11c-0.49,0-0.8-0.29-0.93-0.47C4.03,10.21,3.94,9.78,4.04,9.36z M5,19v-6.03C5.08,12.98,5.15,13,5.23,13 c0.87,0,1.66-0.36,2.24-0.95c0.6,0.6,1.4,0.95,2.31,0.95c0.87,0,1.65-0.36,2.23-0.93c0.59,0.57,1.39,0.93,2.29,0.93 c0.84,0,1.64-0.35,2.24-0.95c0.58,0.59,1.37,0.95,2.24,0.95c0.08,0,0.15-0.02,0.23-0.03V19H5z" />
                  </g>
                </g>
              </svg>
              <p>Details</p>
            </div>
            <div
              className="businessRegisterPageTabs flexColumn flexJustifyAndAlignCenter"
              onClick={() => {
                if (completedSteps >= 2) {
                  setCurrentStep(3);
                }
              }}
              ref={tab3}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z" />
              </svg>
              <p>Address</p>
            </div>
          </div>
          {currentStep === 1 ? (
            <GSTIN
              setError={setError}
              error={error}
              setCompletedSteps={setCompletedSteps}
              setCurrentStep={setCurrentStep}
              setCurrentSubmitData={setCurrentSubmitData}
              currentSubmitData={currentSubmitData}
            />
          ) : null}
          {currentStep === 2 ? (
            <Details
              setError={setError}
              error={error}
              setCompletedSteps={setCompletedSteps}
              setCurrentStep={setCurrentStep}
              setCurrentSubmitData={setCurrentSubmitData}
              currentSubmitData={currentSubmitData}
            />
          ) : null}
          {currentStep === 3 ? (
            <Address
              setError={setError}
              error={error}
              setCompletedSteps={setCompletedSteps}
              setCurrentStep={setCurrentStep}
              setCurrentSubmitData={setCurrentSubmitData}
              currentSubmitData={currentSubmitData}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
