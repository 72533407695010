import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import "./styles.css";
import { axiosBusiness } from "../../axiosInstance";

function LoginPage() {
  const history = useHistory();
  const [error, setError] = useState({});
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const loginData = {
      shopEmail: e.target.shopEmail.value,
      password: e.target.password.value,
    };
    axiosBusiness
      .post("/auth/business/login", loginData)
      .then((res) => {
        setError(res.data);
        if (res.data.status === "success") {
          history.push("/business/inventory");
        }
      })
      .catch((err) => {
        console.log(err.message);
        setError("Something went here");
      });
  };
  return (
    <React.Fragment>
      <div id="loginBodyTop">
        <h1>
          Locaro
          <span style={{ fontSize: "20px", fontWeight: "300" }}> Business</span>
        </h1>
      </div>
      <div id="loginBodyBottom"></div>
      <div id="loginContainer">
        <form id="loginForm" onSubmit={onSubmitHandler}>
          <div className="animatedInputDivs">
            <input
              type="text"
              name="shopEmail"
              id="shopEmailLoginInput"
              placeholder=" "
              autoComplete="off"
              className="animatedInputInputs"
            ></input>
            <label
              htmlFor="shopEmailLoginInput"
              className="animatedInputLabels"
            >
              Shop Email
            </label>
          </div>
          <div className="animatedInputDivs">
            <input
              type="password"
              name="password"
              id="shopPasswordLoginInput"
              placeholder=" "
              autoComplete="off"
              className="animatedInputInputs"
            ></input>
            <label
              htmlFor="shopPasswordLoginInput"
              className="animatedInputLabels"
            >
              Password
            </label>
          </div>
          <button type="submit" style={{ marginTop: "10px" }}>
            Login To Shop
          </button>
        </form>
        <Link to="/business/register" id="goToLoginAndRegister">
          Register
        </Link>
      </div>
    </React.Fragment>
  );
}

export default LoginPage;
