import React, { useState } from "react";
import { axiosBusiness } from "../../axiosInstance";

import ComponentLoading from "../../components/componentLoading";

function Details({
  error,
  setError,
  setCompletedSteps,
  setCurrentStep,
  setCurrentSubmitData,
  currentSubmitData,
}) {
  const [componentLoading, setComponentLoading] = useState(false);

  const submitHandler = (e) => {
    setComponentLoading(true);
    e.preventDefault();
    const submitData = {
      shop_name: e.target.shopName.value,
      shop_email: e.target.shopEmail.value,
      shop_phone_number: e.target.shopPhoneNumber.value,
      password: e.target.shopPassword.value,
    };
    axiosBusiness
      .post("/auth/business/check/details", submitData)
      .then((res) => {
        console.log(res.data);
        setComponentLoading(false);
        if (res.data.status === "success") {
          setCompletedSteps(2);
          setCurrentStep(3);
          setCurrentSubmitData({
            ...currentSubmitData,
            ...submitData,
          });
          setError();
        } else if (res.data.status === "failed") {
          setError(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong!");
        setComponentLoading(false);
      });
  };

  return (
    <form
      className="businessRegisterPageForm flexColumn flexAlignCenter"
      onSubmit={submitHandler}
    >
      {error ? (
        <div
          className="flashCardError"
          style={{
            width: "calc(70% + 30px)",
          }}
        >
          {error}
        </div>
      ) : null}

      <div className="animatedInputDivs">
        <input
          type="text"
          name="shopName"
          placeholder=" "
          autoComplete="off"
          className="animatedInputInputs"
          required
        ></input>
        <label className="animatedInputLabelsLighterGray">Shop Name</label>
      </div>
      <div className="animatedInputDivs">
        <input
          type="text"
          name="shopEmail"
          placeholder=" "
          autoComplete="off"
          className="animatedInputInputs"
          required
        ></input>
        <label className="animatedInputLabelsLighterGray">Email</label>
      </div>
      <div className="animatedInputDivs">
        <input
          type="text"
          name="shopPhoneNumber"
          placeholder=" "
          autoComplete="off"
          className="animatedInputInputs"
          required
        ></input>
        <label className="animatedInputLabelsLighterGray">Phone Number</label>
      </div>
      <div className="animatedInputDivs">
        <input
          type="password"
          name="shopPassword"
          placeholder=" "
          autoComplete="off"
          className="animatedInputInputs"
          required
        ></input>
        <label className="animatedInputLabelsLighterGray">Password</label>
      </div>
      <button type="submit" className="redButton">
        Verify & Continue
        {componentLoading ? <ComponentLoading minHeight={"100%"} /> : null}
      </button>
    </form>
  );
}

export default Details;
