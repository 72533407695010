import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";

import { axiosBusiness } from "../../axiosInstance";
import NavBar from "../../components/NavBar/index";
import Pending from "./Pending.js";
import { LoadingContext } from "../../Context";
import Active from "./Active";
import Cancelled from "./Cancelled";
import Completed from "./Completed";

function OrdersPage() {
  const [pendingOrders, setPendingOrders] = useState();
  const [oldPendingOrders, setOldPendingOrders] = useState();
  const [activeOrders, setActiveOrders] = useState();
  const [cancelledOrders, setCancelledOrders] = useState();
  const [completedOrders, setCompletedOrders] = useState();
  const history = useHistory();
  const [timeoutID, setTimeoutID] = useState();

  const pageObject = {
    PENDING: 1,
    ACTIVE: 2,
    COMPLETED: 3,
    CANCELLED: 4,
  };
  Object.freeze(pageObject);

  const [page, setPage] = useState(pageObject.PENDING);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (!pendingOrders) {
      axiosBusiness
        .get("/business/orders")
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setPendingOrders(
              res.data.orders.filter(
                (order) => order.order_status === "Pending"
              )
            );
            setOldPendingOrders(
              res.data.orders.filter(
                (order) => order.order_status === "Pending"
              )
            );
            setActiveOrders(
              res.data.orders.filter(
                (order) =>
                  order.order_status === "Accepted" &&
                  (order.delivery_status === "On Route" ||
                    order.delivery_status === "Pending")
              )
            );
            setCancelledOrders(
              res.data.orders.filter(
                (order) =>
                  order.order_status === "Declined" ||
                  order.delivery_status === "Cancelled"
              )
            );
            setCompletedOrders(
              res.data.orders.filter(
                (order) =>
                  order.delivery_status === "Delivered" &&
                  order.order_status === "Accepted"
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setTimeoutID(
        setTimeout(() => {
          axiosBusiness
            .get("/business/orders")
            .then((res) => {
              console.log(res.data);
              if (res.data.status === "success") {
                setPendingOrders(
                  res.data.orders.filter(
                    (order) => order.order_status === "Pending"
                  )
                );
                setActiveOrders(
                  res.data.orders.filter(
                    (order) =>
                      order.order_status === "Accepted" &&
                      (order.delivery_status === "On Route" ||
                        order.delivery_status === "Pending")
                  )
                );
                setCancelledOrders(
                  res.data.orders.filter(
                    (order) =>
                      order.order_status === "Declined" ||
                      order.delivery_status === "Cancelled"
                  )
                );
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }, 10000)
      );
    }
  }, [pendingOrders]);

  useEffect(() => {
    if (
      oldPendingOrders &&
      pendingOrders &&
      oldPendingOrders.length < pendingOrders.length
    ) {
      if (Notification.permission === "granted") {
        var notification = new Notification("Locaro", {
          body: "You have a new order",
          // icon: img,
        });
        setOldPendingOrders(pendingOrders);
        notification.onclick = (event) => {
          event.preventDefault();
          window.open(
            `${
              process.env.NODE_ENV === "production"
                ? "https://business.locaro.in/api"
                : "http://localhost:5000/api"
            }/business/orders`,
            "_blank"
          );
        };
      }
    }
  }, [pendingOrders]);

  useEffect(() => {
    if (page === pageObject.PENDING) {
      document
        .querySelector("#orderTabsContainer")
        .children[0].classList.add("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[1].classList.remove("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[2].classList.remove("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[3].classList.remove("orderTabsActive");
    }

    if (page === pageObject.ACTIVE) {
      document
        .querySelector("#orderTabsContainer")
        .children[0].classList.remove("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[1].classList.add("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[2].classList.remove("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[3].classList.remove("orderTabsActive");
    }

    if (page === pageObject.COMPLETED) {
      document
        .querySelector("#orderTabsContainer")
        .children[0].classList.remove("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[1].classList.remove("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[2].classList.add("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[3].classList.remove("orderTabsActive");
    }

    if (page === pageObject.CANCELLED) {
      document
        .querySelector("#orderTabsContainer")
        .children[0].classList.remove("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[1].classList.remove("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[2].classList.remove("orderTabsActive");

      document
        .querySelector("#orderTabsContainer")
        .children[3].classList.add("orderTabsActive");
    }
  }, [page]);

  useEffect(() => {
    Notification.requestPermission();
  }, []);

  return (
    <React.Fragment>
      <NavBar />
      <div id="ordersPageMainContainer" className="flexColumn flexAlignCenter">
        <div id="orderTabsContainer">
          <div
            className="orderTabs"
            style={{ marginLeft: "15px" }}
            onClick={() => {
              setPage(pageObject.PENDING);
            }}
          >
            <h2>
              Pending{" "}
              {pendingOrders && pendingOrders.length > 0 ? (
                <span>{pendingOrders.length}</span>
              ) : null}
            </h2>
          </div>
          <div
            className="orderTabs"
            onClick={() => {
              setPage(pageObject.ACTIVE);
            }}
          >
            <h2>
              Active
              {activeOrders && activeOrders.length > 0 ? (
                <span>{activeOrders.length}</span>
              ) : null}
            </h2>
          </div>
          <div
            className="orderTabs"
            onClick={() => {
              setPage(pageObject.COMPLETED);
            }}
          >
            <h2>Completed</h2>
          </div>
          <div
            className="orderTabs"
            onClick={() => {
              setPage(pageObject.CANCELLED);
            }}
          >
            <h2>Cancelled</h2>
          </div>
        </div>
        {page === pageObject.PENDING ? (
          <Pending
            pendingOrders={pendingOrders}
            setPendingOrders={setPendingOrders}
            timeoutID={timeoutID}
            setActiveOrders={setActiveOrders}
            setCancelledOrders={setCancelledOrders}
          />
        ) : null}
        {page === pageObject.ACTIVE ? (
          <Active
            activeOrders={activeOrders}
            setActiveOrders={setActiveOrders}
            timeoutID={timeoutID}
          />
        ) : null}
        {page === pageObject.CANCELLED ? (
          <Cancelled
            cancelledOrders={cancelledOrders}
            setCancelledOrders={setCancelledOrders}
            timeoutID={timeoutID}
          />
        ) : null}
        {page === pageObject.COMPLETED ? (
          <Completed
            completedOrders={completedOrders}
            setCompletedOrders={setCompletedOrders}
            timeoutID={timeoutID}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
}

export default OrdersPage;
