import React from "react";
import loadingGIF from "./assets/loading.gif";

function Loading() {
  return (
    <div className="overlayDiv">
      <img
        src={loadingGIF}
        alt="loading"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          height: "70px",
          width: "70px",
        }}
      />
    </div>
  );
}

export default Loading;
