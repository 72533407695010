import React, { useState, useEffect } from "react";
import { axiosBusiness } from "../../axiosInstance";
import { useHistory } from "react-router-dom";
import InventoryList from "./InventoryList";

function Inventory({ inventoryData, loaded }) {
  if (window.outerWidth <= 480) {
    return (
      <div id="inventoryListDiv">
        <InventoryList inventoryData={inventoryData} loaded={loaded} />
      </div>
    );
  } else if (window.outerWidth > 480 && window.outerWidth <= 780) {
    return (
      <div id="inventoryListDiv">
        <div id="inventoryColumnLabelDiv">
          <h1 style={{ marginLeft: "30px", textAlign: "left" }}>Product</h1>
          <h1>Availability</h1>
          <h1>Options</h1>
        </div>
        <InventoryList inventoryData={inventoryData} loaded={loaded} />
      </div>
    );
  } else {
    return (
      <div id="inventoryListDiv">
        <div id="inventoryColumnLabelDiv">
          <h1 style={{ marginLeft: "30px", textAlign: "left" }}>Product</h1>
          <h1>Price</h1>
          <h1>Availability</h1>
          <h1>Options</h1>
        </div>
        <InventoryList inventoryData={inventoryData} loaded={loaded} />
      </div>
    );
  }
}

export default Inventory;
