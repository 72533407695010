import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import "./styles.css";
import NavBar from "../../components/NavBar/index";
import Inventory from "./Inventory";
import { axiosBusiness } from "../../axiosInstance";
import InventoryAddPage from "../InventoryAddPage";

function InventoryPage() {
  const history = useHistory();
  const [inventoryData, setInventoryData] = useState([]);
  const [addItemsOverlay, setAddItemsOverlay] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const addItems = () => {
    setAddItemsOverlay(true);
  };

  const getData = () => {
    axiosBusiness
      .get("/business/inventory")
      .then((res) => {
        if (res.data.status === "success") {
          setInventoryData(res.data.inventory);
          setLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        history.push("/business/register");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const searchHandler = (query) => {
    axiosBusiness
      .get("/business/inventory", { params: { query } })
      .then((res) => {
        if (res.data.status === "success") {
          setInventoryData(res.data.inventory);
          setLoaded(true);
        }
      })
      .catch((err) => {
        console.log(err);
        history.push("/business/register");
      });
  };

  if (window.outerWidth <= 480) {
    return (
      <>
        <NavBar />
        <div className="businessInventoryMainContainer">
          <div className="businessInventoryHeaderDiv flex flexAlignEnd">
            <h1 id="inventoryHeader">Inventory</h1>
            <button
              id="addItemsButton"
              onClick={() => {
                addItems();
              }}
            >
              Add Items +
            </button>
          </div>
          <div id="inventoryToolsDiv" className="flex flexAlignCenter">
            <div className="flex" style={{ width: "100%", gap: "5px" }}>
              <input
                type="text"
                placeholder="Search By Name"
                onChange={(e) => {
                  searchHandler(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <Inventory inventoryData={inventoryData} loaded={loaded} />
        </div>
        {addItemsOverlay ? (
          <InventoryAddPage
            setAddItemsOverlay={setAddItemsOverlay}
            getData={getData}
          />
        ) : null}
      </>
    );
  } else {
    return (
      <React.Fragment>
        <NavBar />
        <div className="businessInventoryMainContainer">
          <div className="businessInventoryHeaderDiv flex flexAlignEnd">
            <h1 id="inventoryHeader">Inventory</h1>
            <Link
              to="/business/inventory/how-to"
              id="howToUseInventory"
              className="textDecorNone"
            >
              <h2>&#9432; How To Use</h2>
            </Link>
          </div>
          <div id="inventoryToolsDiv" className="flex flexAlignCenter">
            <div className="flex" style={{ width: "50%", gap: "5px" }}>
              <input
                type="text"
                placeholder="Search By Name"
                onChange={(e) => {
                  searchHandler(e.target.value);
                }}
              ></input>
            </div>
            <button
              id="addItemsButton"
              onClick={() => {
                addItems();
              }}
            >
              Add Items +
            </button>
          </div>
          <Inventory inventoryData={inventoryData} loaded={loaded} />
        </div>
        {addItemsOverlay ? (
          <InventoryAddPage
            setAddItemsOverlay={setAddItemsOverlay}
            getData={getData}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default InventoryPage;
