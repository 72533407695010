import React from "react";
import { Link } from "react-router-dom";

function OrdersHowTo() {
  return (
    <>
      <div className="howToJumpLinksDiv">
        <h1>Quick Access :</h1>
        <a href="#howToAcceptOrders" className="howToJumpLinks">
          <h3>Accepting Orders</h3>
        </a>
        <a href="#howToDeclineOrders" className="howToJumpLinks">
          <h3>Declining Orders</h3>
        </a>
        <a href="#howToCheckIfDelivered" className="howToJumpLinks">
          <h3>Check If Delivered</h3>
        </a>
      </div>
      <div className="howToInfoContainer">
        <div id="howToAcceptOrders">
          <h1>How to accept orders:</h1>
          <ol className="howToOrderedList">
            <li>
              Open your <Link>Orders page</Link>.
            </li>
            <li>
              By default the pending tab should be open, if not click on the
              'pending' tab.
            </li>
            <li>
              To accept the order you want to accept, just simply click on the
              'Accept' button at the bottom of the order's card.
            </li>
            <li>
              You will then be able to see this order in the 'Active' tab.
            </li>
          </ol>
        </div>
        <div id="howToDeclineOrders">
          <h1>How to Decline orders:</h1>
          <ol className="howToOrderedList">
            <li>
              Open your <Link>Orders page</Link>.
            </li>
            <li>
              By default the pending tab should be open, if not click on the
              'pending' tab.
            </li>
            <li>
              To decline the order you want to decline, just simply click on the
              'decline' button at the bottom of the order's card.
            </li>
            <li>
              And then you will have to give a reason for declining the order.
            </li>
            <li>You will be able to see this order in the 'Cancelled' Tab.</li>
          </ol>
        </div>
        <div id="howToCheckIfDelivered">
          <h1>How to check if product is delivered:</h1>
          <ol className="howToOrderedList">
            <li>
              Open your <Link>Orders page</Link>.
            </li>
            <li>Click on the 'Completed' tab.</li>
            <li>All completed/delivered orders will be shown here.</li>
          </ol>
        </div>
      </div>
    </>
  );
}

export default OrdersHowTo;
