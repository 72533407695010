import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import Navbar from "./Navbar";
import { LoadingContext, ShopDetailsContext } from "../../Context";
import { axiosBusiness } from "../../axiosInstance";

import "./styles.css";
import locaroSVG from "./assets/locaro.svg";
import Details from "./Details";
import Footer from "./Footer";
import Menu from "./Menu";
import { Link } from "react-router-dom";

function LandingPage() {
  const body = useRef();
  const { setLoading } = useContext(LoadingContext);
  const { shopDetails, setShopDetails } = useContext(ShopDetailsContext);
  const history = useHistory();
  const [menu, setMenu] = useState(false);

  const getData = () => {
    if (!shopDetails) {
      setLoading(true);
      axiosBusiness
        .get("/business/details")
        .then((res) => {
          if (res.data.status === "success") {
            setShopDetails(res.data.shop);
            setLoading(false);
            history.push("/business/inventory");
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      history.push("/business/inventory");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // const options = {
  //   threshold: 0,
  //   rootMargin: "0px 0px -250px 0px",
  // };

  // const { ref: lowerSections, inView, entry } = useInView(options);

  // useEffect(() => {
  //   if (inView === true) {
  //     // body.current.style.background = "#333333";
  //     navBarRef.current.style.background = "#333333";
  //   } else {
  //     // body.current.style.background = "#b90504";
  //     navBarRef.current.style.background = "#b90504";
  //   }
  // }, [inView]);

  return (
    <div className="landingPageBody" ref={body}>
      <Navbar setMenu={setMenu} />
      <section id="homeSection" className="flex flexJustifyAndAlignCenter">
        <div className="homeSectionContentContainer flex">
          <div className="homeSectionTextDiv flexColumn flexJustifyCenter">
            <h1>
              Deliver anything
              <br />
              from your shop.
            </h1>
            <p>Start growing your business!</p>
            <Link
              className="flex flexAlignCenter textDecorNone"
              to="/business/register"
            >
              Sell on Locaro
            </Link>
          </div>
          <div className="homeSectionImageDiv flex flexJustifyAndAlignCenter">
            <img src={locaroSVG} alt="" />
          </div>
        </div>
      </section>
      <div style={{ width: "100%", backgroundColor: "#333333" }}>
        <Details />
        <Footer />
      </div>
      {menu ? <Menu setMenu={setMenu} /> : null}
    </div>
  );
}

export default LandingPage;
