import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import menuSVG from "./assets/menu.svg";

function Navbar({ setMenu }) {
  const navBarRef = useRef();

  useEffect(() => {
    if (navBarRef.current !== undefined) {
      var lastScrollTop = 0;
      window.addEventListener("scroll", () => {
        let scrollTop = window.scrollY || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop) {
          navBarRef.current.style.top = "-90px";
        } else {
          navBarRef.current.style.top = "0";
        }
        lastScrollTop = scrollTop;
      });
    }

    return () => {
      if (navBarRef && navBarRef.current) {
        window.removeEventListener("scroll", () => {
          let scrollTop = window.scrollY || document.documentElement.scrollTop;
          if (scrollTop > lastScrollTop) {
            navBarRef.current.style.top = "-90px";
          } else {
            navBarRef.current.style.top = "0";
          }
          lastScrollTop = scrollTop;
        });
      }
    };
  }, [navBarRef]);

  return (
    <div ref={navBarRef} className="landingPageNavBarDiv flex flexAlignCenter">
      <h1>Locaro</h1>
      <div className="landingPageNavBarLinksDiv flex flexAlignCenter">
        <a href="#homeSection">Home</a>
        <a href="#detailsSection">Details</a>
        <a href="#footer">Support</a>
        <Link
          to="/business/login"
          style={{ marginLeft: "20px", fontWeight: "500" }}
        >
          Login
        </Link>
        <Link to="/business/register" style={{ fontWeight: "500" }}>
          Register
        </Link>
      </div>
      {window.outerWidth <= 780 ? (
        <img
          src={menuSVG}
          style={{ marginLeft: "auto" }}
          onClick={() => {
            setMenu(true);
          }}
        />
      ) : null}
    </div>
  );
}

export default Navbar;
