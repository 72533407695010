import React, { useContext, useState, useEffect } from "react";
import { axiosBusiness } from "../../axiosInstance";
import NavBar from "../../components/NavBar";
import { ShopDetailsContext } from "../../Context";
import RigthDiv from "./RigthDiv";
import ShopInfoCard from "./ShopInfoCard";

function DashboardPage() {
  const { shopDetails } = useContext(ShopDetailsContext);
  const [shopStats, setShopStats] = useState();

  useEffect(() => {
    axiosBusiness
      .get("/business/dashboard")
      .then((res) => {
        console.log(res.data);
        setShopStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <NavBar />
      {shopDetails ? (
        <div id="dashboardMainContainer" className="flex">
          <ShopInfoCard />
          {shopStats ? <RigthDiv shopStats={shopStats} /> : null}
        </div>
      ) : null}
    </>
  );
}

export default DashboardPage;
