import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { axiosBusiness } from "../../axiosInstance.js";

import "./styles.css";
import { ShopDetailsContext } from "../../Context";

function DropDown() {
  const { setShopDetails } = useContext(ShopDetailsContext);
  const history = useHistory();
  const logOutHandler = () => {
    axiosBusiness.get("/auth/business/logout").then((res) => {
      if (res.data.status === "success") {
        setShopDetails();
        console.log("logged out");
        history.push("/business/login");
      }
    });
  };

  return (
    <div id="dropDownDiv">
      <Link to="/business/edit-profile">
        <div className="dropDownOptionDivs">
          <h2>Edit Profile</h2>
        </div>
      </Link>
      <div className="dropDownOptionDivs" onClick={logOutHandler}>
        <h2>Log Out</h2>
      </div>
    </div>
  );
}

export default DropDown;
