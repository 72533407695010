import React, { useRef } from "react";
import { Link } from "react-router-dom";

function Menu({ setMenu }) {
  const overlay = useRef();
  const menuDiv = useRef();
  return (
    <div
      className="overlayDiv flex"
      style={{ touchAction: "none", animationName: "overlayFadeIn" }}
      ref={overlay}
    >
      <div
        style={{ width: "100%" }}
        onClick={() => {
          menuDiv.current.style.animationName = "menuSlideOutRight";
          overlay.current.style.animationName = "overlayFadeOut";
          setTimeout(() => {
            setMenu(false);
          }, 480);
        }}
      ></div>
      <div className="landingPageMobileMenu" ref={menuDiv}>
        <div className="landingPageMobileMenuTopBar flex flexJustifyAndAlignCenter">
          Locaro
        </div>
        <Link
          to="/business/login"
          className="landingPageMobileMenuOptions flex textDecorNone"
        >
          Login
        </Link>
        <Link
          to="/business/register"
          className="landingPageMobileMenuOptions flex textDecorNone"
        >
          Register
        </Link>
      </div>
    </div>
  );
}

export default Menu;
