import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { axiosBusiness } from "../../axiosInstance";

function InventoryList({ inventoryData, loaded }) {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  if (inventoryData.length !== 0) {
    if (window.outerWidth <= 480) {
      return (
        <React.Fragment>
          {inventoryData.map((products) => {
            return (
              <div key={products.product_uid} className="inventoryCard">
                <div className="businessInventoryProductImageAndTextDiv flex">
                  <div style={{ height: "100%" }}>
                    <div className="businessInventoryProductImageDiv flex flexJustifyAndAlignCenter">
                      <img
                        src={`${
                          process.env.NODE_ENV === "production"
                            ? "https://business.locaro.in/api"
                            : "http://localhost:5000/api"
                        }/images/products/${
                          Array.isArray(products.product_images)
                            ? products.product_images[0]
                            : "null"
                        }`}
                        alt="sample"
                      />
                    </div>
                    <div
                      className="businessInventoryMobileAvailabilityTag flex flexJustifyAndAlignCenter"
                      style={{
                        backgroundColor: products.is_available
                          ? "green"
                          : "#b90504",
                      }}
                    >
                      {products.is_available === true ? "Active" : "Inactive"}
                    </div>
                  </div>
                  <div className="businessInventoryProductTextDiv flexColumn">
                    <h2 className="text3Lines">{products.product_name}</h2>
                    {products.is_grocery === true ? (
                      <div className="groceryTag">Grocery</div>
                    ) : null}
                    <h3>
                      Price:{" "}
                      <span>{formatter.format(products.product_price)}</span>
                    </h3>
                  </div>
                </div>
                <div className="inventoryOptionColumnDiv">
                  <Link to={"/business/inventory/" + products.product_uid}>
                    <button className="redButton">Edit</button>
                  </Link>
                </div>
              </div>
            );
          })}
        </React.Fragment>
      );
    } else if (window.outerWidth > 480 && window.outerWidth <= 780) {
      return (
        <React.Fragment>
          {inventoryData.map((products) => {
            return (
              <div key={products.product_uid} className="inventoryCard">
                <div className="businessInventoryProductImageAndTextDiv flex flexAlignCenter">
                  <div className="businessInventoryProductImageDiv flex flexJustifyAndAlignCenter">
                    <img
                      src={`${
                        process.env.NODE_ENV === "production"
                          ? "https://business.locaro.in/api"
                          : "http://localhost:5000/api"
                      }/images/products/${
                        Array.isArray(products.product_images)
                          ? products.product_images[0]
                          : "null"
                      }`}
                      alt="sample"
                    />
                  </div>
                  <div className="businessInventoryProductTextDiv flexColumn flexJustifyCenter">
                    <h2 className="text3Lines">{products.product_name}</h2>
                    {products.is_grocery === true ? (
                      <div className="groceryTag">Grocery</div>
                    ) : null}
                    <h3>
                      Price:{" "}
                      <span>{formatter.format(products.product_price)}</span>
                    </h3>
                  </div>
                </div>
                <div className="inventoryAvailabilityColumnDiv">
                  <button
                    className="redButton"
                    style={{
                      backgroundColor: products.is_available
                        ? "green"
                        : "#b90504",
                    }}
                  >
                    {products.is_available === true ? "Active" : "Inactive"}
                  </button>
                </div>
                <div className="inventoryOptionColumnDiv">
                  <Link to={"/business/inventory/" + products.product_uid}>
                    <button className="redButton">Update</button>
                  </Link>
                </div>
              </div>
            );
          })}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {inventoryData.map((products) => {
            return (
              <div key={products.product_uid} className="inventoryCard">
                <div className="businessInventoryProductImageAndTextDiv flex flexAlignCenter">
                  <div className="businessInventoryProductImageDiv flex flexJustifyAndAlignCenter">
                    <img
                      src={`${
                        process.env.NODE_ENV === "production"
                          ? "https://business.locaro.in/api"
                          : "http://localhost:5000/api"
                      }/images/products/${
                        Array.isArray(products.product_images)
                          ? products.product_images[0]
                          : "null"
                      }`}
                      alt="sample"
                    />
                  </div>
                  <div className="businessInventoryProductTextDiv flexColumn flexJustifyCenter">
                    <h2 className="text4Lines">{products.product_name}</h2>
                    {products.is_grocery === true ? (
                      <div className="groceryTag">Grocery</div>
                    ) : null}
                  </div>
                </div>
                <div className="inventoryPriceColumnDiv">
                  <h2>{formatter.format(products.product_price)}</h2>
                </div>
                <div className="inventoryAvailabilityColumnDiv">
                  <button
                    className="redButton"
                    style={{
                      backgroundColor: products.is_available
                        ? "green"
                        : "#b90504",
                    }}
                  >
                    {products.is_available === true ? "Active" : "Inactive"}
                  </button>
                </div>
                <div className="inventoryOptionColumnDiv">
                  <Link to={"/business/inventory/" + products.product_uid}>
                    <button className="redButton">Update</button>
                  </Link>
                </div>
              </div>
            );
          })}
        </React.Fragment>
      );
    }
  }
  if (inventoryData.length === 0 && loaded) {
    return (
      <React.Fragment>
        <h1
          style={{
            textAlign: "center",
            fontFamily: "Montserrat",
            color: "dimgray",
            marginTop: "30px",
          }}
        >
          Nothing Here. Please add a product
        </h1>
      </React.Fragment>
    );
  } else {
    return (
      <h1
        style={{
          textAlign: "center",
          fontFamily: "Montserrat",
          color: "dimgray",
          marginTop: "30px",
        }}
      >
        Loading...
      </h1>
    );
  }
}

export default InventoryList;
