import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "./styles.css";

import { axiosBusiness } from "../../axiosInstance.js";
import { LoadingContext } from "../../Context";
import searchIcon from "./assets/searchButton.png";

function InventoryAddPage({ setAddItemsOverlay, getData }) {
  const history = useHistory();
  const [availability, setAvailability] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const [MRP, setMRP] = useState(0);
  const [display, setDisplay] = useState("none");
  const [selectedProduct, setSelectedProduct] = useState();
  const selectedSearchInput = useRef();
  const searchInput = useRef();
  const [error, setError] = useState();

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const submitHandler = (e) => {
    e.preventDefault();
    if (selectedProduct) {
      setLoading(true);
      const productData = {
        catalog_uid: selectedProduct.catalog_uid,
        price: e.target.price.value,
        available: availability,
        mrp: MRP,
      };
      axiosBusiness
        .post("business/addproduct", productData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setAddItemsOverlay(false);
            setLoading(false);
            getData();
          } else if (res.data.status === "failed") {
            setLoading(false);
            history.push("/business/inventory");
            setError("Price set greater than MRP or not set at all");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
        });
    } else {
      setError(
        "No product selected, please search a product and click on the suggestions given"
      );
    }
  };

  const getSuggestions = () => {
    let name = searchInput.current.value;
    if (name.length > 0) {
      axiosBusiness
        .get("/business/catalog", { params: { query: name } })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setSuggestions(res.data.catalog);
            setDisplay("block");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const onClickHandler = (suggestion) => {
    setDisplay("none");
    setMRP(suggestion.product_mrp);
    setSelectedProduct(suggestion);
    selectedSearchInput.current.value = suggestion.product_name;
  };

  const changeAvailablity = () => {
    if (availability === true) {
      setAvailability(false);
    } else {
      setAvailability(true);
    }
  };

  useEffect(() => {
    if (availability === false) {
      document.querySelector("#isAvailableButton").style.backgroundColor =
        "#b90504";
    } else {
      document.querySelector("#isAvailableButton").style.backgroundColor =
        "green";
    }
  }, [availability]);

  useEffect(() => {
    console.log(suggestions);
  }, [suggestions]);

  return (
    <div className="overlayDiv" style={{ touchAction: "none" }}>
      {error ? (
        <div
          className="flashCardError"
          style={{
            marginTop: "80px",
            width: window.outerWidth < 480 ? "95%" : "400px",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%,0)",
          }}
        >
          {error}
        </div>
      ) : null}
      <div id="addProductDiv">
        <div
          className="closeButton"
          onClick={() => {
            setAddItemsOverlay(false);
          }}
        >
          &times;
        </div>
        <form id="addProductForm" onSubmit={submitHandler}>
          <div className="addProductSearchInputDiv flexColumn">
            <input
              type="text"
              style={{ marginBottom: "20px" }}
              placeholder="Selected Product"
              ref={selectedSearchInput}
              disabled
            />
            <input
              type="text"
              name="productSearch"
              placeholder="Search And Select Product"
              autoComplete="off"
              ref={searchInput}
            />
            <div
              className="addProductSearchButton flex flexJustifyAndAlignCenter"
              onClick={() => {
                getSuggestions();
              }}
            >
              <img src={searchIcon} alt="" />
            </div>
            <div
              className="inventoryAddProductSearchSuggestions"
              style={{ display: display }}
            >
              {suggestions.map((suggestion) => {
                return (
                  <div
                    className="inventoryAddProductSuggestionCard"
                    key={suggestion.catalog_uid}
                    onClick={() => {
                      onClickHandler(suggestion);
                    }}
                  >
                    {suggestion.product_name}
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ marginTop: "40px", width: "90%" }}>
            <h1>MRP : {formatter.format(MRP)}</h1>
            <h2>*Cannnot set price above MRP*</h2>
            <input name="price" type="number" placeholder="Price"></input>
          </div>
          <button
            type="button"
            id="isAvailableButton"
            onClick={() => changeAvailablity()}
          >
            {availability === true ? "Available" : "Not Available"}
          </button>
          <button
            type="submit"
            className="redButton"
            style={{ position: "absolute", bottom: "10px" }}
          >
            Add Product
          </button>
        </form>
      </div>
    </div>
  );
}

export default InventoryAddPage;
