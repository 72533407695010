import React, { useRef, useEffect, useState, useContext } from "react";
import "./styles.css";

import { axiosBusiness } from "../../axiosInstance";
import { LoadingContext } from "../../Context";

function Pending({
  pendingOrders = [],
  setPendingOrders,
  timeoutID,
  setActiveOrders,
  setCancelledOrders,
}) {
  const { setLoading } = useContext(LoadingContext);

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const acceptHandler = (order) => {
    setLoading(true);
    axiosBusiness
      .post("/business/orders/accept", { order })
      .then((res) => {
        if (res.data.status === "success") {
          console.log(res.data);
          clearTimeout(timeoutID);
          axiosBusiness
            .get("/business/orders")
            .then((res) => {
              if (res.data.status === "success") {
                setLoading(false);
                setPendingOrders(
                  res.data.orders.filter(
                    (order) => order.order_status === "Pending"
                  )
                );
                setActiveOrders(
                  res.data.orders.filter(
                    (order) =>
                      order.order_status === "Accepted" &&
                      (order.delivery_status === "On Route" ||
                        order.delivery_status === "Pending")
                  )
                );
                setCancelledOrders(
                  res.data.orders.filter(
                    (order) =>
                      order.order_status === "Declined" ||
                      order.delivery_status === "Cancelled"
                  )
                );
              } else {
                setLoading(false);
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const declineHandler = (order) => {
    setLoading(true);
    axiosBusiness
      .post("/business/orders/decline", { order })
      .then((res) => {
        if (res.data.status === "success") {
          console.log(res.data);
          clearTimeout(timeoutID);
          axiosBusiness
            .get("/business/orders")
            .then((res) => {
              if (res.data.status === "success") {
                setLoading(false);
                setPendingOrders(
                  res.data.orders.filter(
                    (order) => order.order_status === "Pending"
                  )
                );
                setActiveOrders(
                  res.data.orders.filter(
                    (order) =>
                      order.order_status === "Accepted" &&
                      (order.delivery_status === "On Route" ||
                        order.delivery_status === "Pending")
                  )
                );
                setCancelledOrders(
                  res.data.orders.filter(
                    (order) =>
                      order.order_status === "Declined" ||
                      order.delivery_status === "Cancelled"
                  )
                );
              } else {
                setLoading(false);
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="ordersContainer flex">
      {pendingOrders.map((order) => {
        return (
          <div className="orderCard Pending" key={order.order_uid}>
            <div className="orderTag Pending">Pending</div>
            <div className="ordersAddressDiv flex flexAlignCenter">
              <p className="text3Lines">
                <span>{order.user_full_name}</span>, {order.user_address}
              </p>
            </div>
            <div className="ordersOrderedProductsDiv flexColumn">
              {order.order_items.map((product) => {
                return (
                  <div
                    className="ordersItemDiv flex"
                    key={product.order_item_uid}
                  >
                    <h2>{product.product_name}</h2>
                    <h3>
                      {formatter.format(product.product_price)}
                      <br /> &times; {product.product_count}
                    </h3>
                  </div>
                );
              })}
            </div>
            <div className="ordersButtonDiv flex">
              <button
                className="ordersButton"
                onClick={() => {
                  acceptHandler(order);
                }}
              >
                Accept
              </button>
              <button
                className="ordersButton"
                onClick={() => {
                  declineHandler(order);
                }}
              >
                Decline
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Pending;
