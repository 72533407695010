import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import LoginPage from "./AuthPages/BusinessLoginPage/index";
import InventoryPage from "./PortalPages/InventoryPage/index";
import OrdersPage from "./PortalPages/OrdersPage/index";
import RegisterPage from "./AuthPages/BusinessRegisterPage/index";
import InventoryAddPage from "./PortalPages/InventoryAddPage/index";
import InventoryEditPage from "./PortalPages/InventoryEditPage/index";
import InventoryHowTo from "./HowToPages/InventoryHowTo/index";
import OrdersHowTo from "./HowToPages/OrdersHowTo";
import Loading from "./components/loading/index";

import { axiosBusiness } from "./axiosInstance";
import { LoadingContext, ShopDetailsContext } from "./Context.js";
import DashboardPage from "./PortalPages/DashboardPage";
import LandingPage from "./PortalPages/LandingPage";

const App = () => {
  const [loading, setLoading] = useState(false);
  const [shopDetails, setShopDetails] = useState();

  useEffect(() => {
    console.log(shopDetails);
  }, [shopDetails]);

  return (
    <LoadingContext.Provider value={{ setLoading, loading }}>
      <ShopDetailsContext.Provider value={{ shopDetails, setShopDetails }}>
        <Router>
          <Switch>
            <Route exact path="/">
              <LandingPage />
              {loading ? <Loading /> : null}
            </Route>
            <Route exact path="/business/register">
              <RegisterPage />
              {loading ? <Loading /> : null}
            </Route>
            <Route exact path="/business/login">
              <LoginPage />
              {loading ? <Loading /> : null}
            </Route>
            <Route exact path="/business/inventory">
              <InventoryPage />
              {loading ? <Loading /> : null}
            </Route>
            <Route exact path="/business/orders">
              <OrdersPage />
              {loading ? <Loading /> : null}
            </Route>
            <Route exact path="/business/inventory/addProduct">
              <InventoryAddPage />
              {loading ? <Loading /> : null}
            </Route>
            <Route exact path="/business/inventory/how-to">
              <InventoryHowTo />
            </Route>
            <Route exact path="/business/orders/how-to">
              <OrdersHowTo />
            </Route>
            <Route path="/business/inventory/:id">
              <InventoryEditPage />
              {loading ? <Loading /> : null}
            </Route>
            <Route exact path="/business/dashboard">
              <DashboardPage />
              {loading ? <Loading /> : null}
            </Route>
            <Route path="*">
              <Redirect to="/business/inventory" />
            </Route>
          </Switch>
        </Router>
      </ShopDetailsContext.Provider>
    </LoadingContext.Provider>
  );
};

export default App;
