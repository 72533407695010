import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

function InventoryHowTo() {
  return (
    <>
      <div className="howToJumpLinksDiv">
        <h1>Quick Access :</h1>
        <a href="#howToAddProduct" className="howToJumpLinks">
          <h3>Adding Products</h3>
        </a>
        <a href="#howToEditProduct" className="howToJumpLinks">
          <h3>Editing Products</h3>
        </a>
        <a href="#howToDeleteProduct" className="howToJumpLinks">
          <h3>Delete Products</h3>
        </a>
        <a href="#howToChangeAvailability" className="howToJumpLinks">
          <h3>Change Availibility</h3>
        </a>
      </div>
      <div className="howToInfoContainer">
        <div id="howToAddProduct">
          <h1>How to add your product to your inventory:</h1>
          <ol className="howToOrderedList">
            <li>
              Open your <Link to="/business/inventory">Inventory page</Link>
            </li>
            <li>
              Click on the 'add product +' button in the tool bar above your
              list
            </li>
            <li>
              you will be redirected to a form containing a search bar, a price
              input and an availability button you can click to toggle the
              availability of the product.
            </li>
            <li>
              Once you are done entering all the info required, click on the
              'Add product' button at the bottom of the form.
            </li>
            <li>
              And there you go, you have added a product to your inventory
            </li>
          </ol>
        </div>
        <div id="howToEditProduct">
          <h1>How to edit your product in your inventory:</h1>
          <ol className="howToOrderedList">
            <li>
              Open your <Link to="/business/inventory">Inventory page</Link>
            </li>
            <li>
              Click on the 'Edit' button in the options next to your product you
              want to edit.
            </li>
            <li>
              you will be redirected to a form containing a price input and an
              availability button you can click to toggle the availability of
              the product.
            </li>
            <li>
              Once you are done entering all the info required, click on the
              'Update' button at the bottom of the form.
            </li>
            <li>
              And there you go, you have edited a product in your inventory
            </li>
          </ol>
        </div>
        <div id="howToDeleteProduct">
          <h1>How to delete a product in your inventory:</h1>
          <ol className="howToOrderedList">
            <li>
              Open your <Link to="/business/inventory">Inventory page</Link>
            </li>
            <li>
              Click on the 'Edit' button in the options next to your product you
              want to edit.
            </li>
            <li>
              you will be redirected to a form containing a price input and an
              availability button you can click to toggle the availability of
              the product.
            </li>
            <li>Click on the delete button next to the update button.</li>
            <li>
              And there you go, you have deleted a product in your inventory
            </li>
          </ol>
        </div>
        <div id="howToChangeAvailability">
          <h1>
            How to change the availability status of a product in your inventory
          </h1>
          <ol className="howToOrderedList">
            <li>
              Open your <Link to="/business/inventory">Inventory page</Link>
            </li>
            <li>
              Click on the 'Edit' button in the options next to your product you
              want to edit.
            </li>
            <li>
              you will be redirected to a form containing a price input and an
              availability button you can click to toggle the availability of
              the product.
            </li>
            <li>
              Click on the the button that either says 'available' or 'not
              available' and set it to whatever you want.
            </li>
            <li>
              Once done, click on the 'Update' button at the bottom of the form.
            </li>
            <li>
              And there you go, you have deleted a product in your inventory
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}

export default InventoryHowTo;
