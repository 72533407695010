import React, { useState } from "react";
import { axiosBusiness } from "../../axiosInstance";

import ComponentLoading from "../../components/componentLoading";

function GSTIN({
  error,
  setError,
  setCompletedSteps,
  setCurrentStep,
  setCurrentSubmitData,
  currentSubmitData,
}) {
  const [componentLoading, setComponentLoading] = useState(false);

  const submitHandler = (e) => {
    setComponentLoading(true);
    e.preventDefault();
    axiosBusiness
      .post("/auth/business/check/account", {
        shop_gstin: e.target.shopGSTIN.value,
        shop_upi: e.target.shopUPI.value,
      })
      .then((res) => {
        console.log(res.data);
        setComponentLoading(false);
        if (res.data.status === "success") {
          setError();
          setCompletedSteps(1);
          setCurrentStep(2);
          setCurrentSubmitData({
            ...currentSubmitData,
            shop_gstin: e.target.shopGSTIN.value,
            shop_upi: e.target.shopUPI.value,
          });
        } else if (res.data.status === "failed") {
          setError("Invalid GSTIN number");
        }
      })
      .catch((err) => {
        console.log(err);
        setComponentLoading(false);
        setError("Something went wrong!");
      });
  };
  return (
    <form
      className="businessRegisterPageForm flexColumn flexAlignCenter"
      onSubmit={submitHandler}
    >
      {error ? (
        <div
          className="flashCardError"
          style={{
            width: "calc(70% + 30px)",
          }}
        >
          {error}
        </div>
      ) : null}

      <div className="animatedInputDivs">
        <input
          type="text"
          name="shopGSTIN"
          placeholder=" "
          autoComplete="off"
          className="animatedInputInputs"
          required
        ></input>
        <label className="animatedInputLabelsLighterGray">GSTIN</label>
      </div>
      <p>
        Locaro needs your GSTIN number to file tax returns and verify your
        business
      </p>
      <div className="animatedInputDivs" style={{ marginTop: "30px" }}>
        <input
          type="text"
          name="shopUPI"
          placeholder=" "
          autoComplete="off"
          className="animatedInputInputs"
          required
        ></input>
        <label className="animatedInputLabelsLighterGray">UPI Address</label>
      </div>
      <p style={{ marginBottom: "30px" }}>
        Your UPI address is required so we can send your earnings to you every
        month
      </p>
      <button type="submit" className="redButton">
        Verify & Continue
        {componentLoading ? <ComponentLoading minHeight={"100%"} /> : null}
      </button>
    </form>
  );
}

export default GSTIN;
