import React from "react";

import "./styles.css";
import componentLoadingSVG from "./assets/componentLoading.svg";

function ComponentLoading(props) {
  const { position = "absolute", minHeight = "150px" } = props;
  return (
    <div
      className="componentLoadingDiv flex flexJustifyAndAlignCenter"
      style={{ position: position, minHeight: minHeight }}
    >
      <img src={componentLoadingSVG} alt="" />
    </div>
  );
}

export default ComponentLoading;
