import React, { useState, useEffect, useContext } from "react";

import { NavLink, useLocation, useHistory } from "react-router-dom";
import DropDown from "./DropDown";
import menuSVG from "./assets/menu.svg";

import { LoadingContext, ShopDetailsContext } from "../../Context";

import { axiosBusiness } from "../../axiosInstance.js";

import "./styles.css";
import MobileMenu from "./MobileMenu";

function NavBar() {
  const { setLoading } = useContext(LoadingContext);
  const { shopDetails, setShopDetails } = useContext(ShopDetailsContext);
  const history = useHistory();
  const [dropDown, setDropDown] = useState();

  const getData = () => {
    if (!shopDetails) {
      setLoading(true);
      axiosBusiness
        .get("/business/details")
        .then((res) => {
          if (res.data.status === "success") {
            setShopDetails(res.data.shop);
            setLoading(false);
          }
        })
        .catch((err) => {
          history.push("/business/register");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (window.outerWidth <= 748) {
    return (
      <>
        <div id="navBar">
          <div id="navTabs">
            <img
              src={menuSVG}
              alt=""
              onClick={() => {
                setDropDown("menu");
              }}
            />
            <h1 id="locaroHeader">Locaro</h1>
          </div>
          <div
            id="shopDropDown"
            onClick={() => {
              dropDown === "inactive"
                ? setDropDown("active")
                : setDropDown("inactive");
            }}
          >
            <h1>{shopDetails ? shopDetails.shop_name : "Loading..."} </h1>
          </div>
        </div>
        {dropDown === "menu" ? <MobileMenu setDropDown={setDropDown} /> : null}
      </>
    );
  } else {
    return (
      <React.Fragment>
        <div id="navBar">
          <div id="navTabs">
            <h1 id="locaroHeader">Locaro</h1>
            <NavLink
              exact
              to="/business/dashboard"
              className="navTabs"
              activeClassName="navTabActive"
            >
              Dashboard
            </NavLink>
            <NavLink
              exact
              to="/business/inventory"
              activeClassName="navTabActive"
              className="navTabs"
            >
              Inventory
            </NavLink>
            <NavLink
              exact
              to="/business/orders"
              className="navTabs"
              activeClassName="navTabActive"
            >
              Orders
            </NavLink>
          </div>
          <div
            id="shopDropDown"
            onClick={() => {
              dropDown === "account" ? setDropDown("") : setDropDown("account");
            }}
          >
            <h1>
              {shopDetails ? shopDetails.shop_name : "Loading..."}{" "}
              <span style={{ fontSize: "0.9em" }}>&#x25BE;</span>
            </h1>
          </div>
          {dropDown === "account" ? <DropDown /> : null}
        </div>
      </React.Fragment>
    );
  }
}

export default NavBar;
