import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { axiosBusiness } from "../../axiosInstance.js";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation, Autoplay } from "swiper";

import "./styles.css";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

SwiperCore.use([Navigation, Autoplay]);

function Details() {
  const faders = useRef(new Array());
  const [shops, setShops] = useState([]);
  const history = useHistory();

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     axiosBusiness
  //       .post("/public/business/shops/nearby", {
  //         latitude: position.coords.latitude,
  //         longitude: position.coords.longitude,
  //       })
  //       .then((res) => {
  //         console.log(res.data);
  //         if (res.data.status === "success") {
  //           setShops(res.data.shops);
  //         }
  //       })
  //       .catch(
  //         (err) => {
  //           console.log(err);
  //         },
  //         () => {
  //           console.log("Unable to retrieve your location");
  //         }
  //       );
  //   });
  // }, []);

  useEffect(() => {
    axiosBusiness
      .post("/public/business/shops/nearby", {
        latitude: 19.0459353,
        longitude: 73.0903888,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setShops(res.data.shops);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const appearOptions = {
    threshold: 0,
    rootMargin: "0px 0px -20% 0px",
  };

  const appearOnScroll = new IntersectionObserver((entries, appearOnScroll) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      } else {
        entry.target.classList.add("landingPageAppear");
        appearOnScroll.unobserve(entry.target);
      }
    });
  }, appearOptions);

  useEffect(() => {
    if (faders.current[0]) {
      faders.current.forEach((fader) => {
        appearOnScroll.observe(fader);
      });
    }
  }, [faders]);

  return (
    <section id="detailsSection" className="flexColumn">
      <div className="landingPageHowItWorksDiv flexColumn flexAlignCenter">
        <h1>How it works</h1>
        <div className="landingPageHowItWorksCardDiv flex">
          <div
            className="landingPageHowItWorksCard flexColumn flexJustifyAndAlignCenter"
            ref={(element) => {
              faders.current.push(element);
            }}
          >
            <h2>Register</h2>
            <p>Register your shop with Locaro using your GSTIN</p>
          </div>
          <div
            className="landingPageHowItWorksCard flexColumn flexJustifyAndAlignCenter"
            ref={(element) => {
              faders.current.push(element);
            }}
          >
            <h2>Inventory</h2>
            <p>Set the products you're selling on the inventory page.</p>
          </div>
          <div
            className="landingPageHowItWorksCard flexColumn flexJustifyAndAlignCenter"
            ref={(element) => {
              faders.current.push(element);
            }}
          >
            <h2>Accept</h2>
            <p>Start accepting orders from customers!</p>
          </div>
        </div>
      </div>
      {shops[0] ? (
        <div
          className="flexColumn flexAlignCenter"
          style={{ marginTop: "50px" }}
        >
          <h1
            style={{
              fontSize: window.outerWidth < 480 ? "25px" : "38px",
              fontFamily: "Montserrat",
              fontWeight: "bold",
              color: "white",
              textAlign: "center",
            }}
          >
            Join other shops selling near you!
          </h1>
          <Swiper
            slidesPerView={1.05}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
            }}
            spaceBetween={10}
            centeredSlides={true}
            navigation={window.outerWidth > 480}
            className="landingPageShopSwiper"
            breakpoints={{
              480: {
                slidesPerView: 1.5,
                spaceBetween: 50,
              },
              780: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
              1025: {
                slidesPerView: 2.5,
                spaceBetween: 50,
              },
            }}
          >
            {shops.map((shop) => {
              return (
                <SwiperSlide key={shop.shop_uid}>
                  <a
                    className="landingPageShopCard card-boxshadow flex textDecorNone"
                    style={{
                      backgroundImage: `url(${
                        process.env.NODE_ENV === "production"
                          ? "https://business.locaro.in/api"
                          : "http://localhost:5000/api"
                      }/images/${shop.shop_image})`,
                    }}
                    href={`https://locaro.in/shop/${shop.shop_uid}`}
                  >
                    <div className="landingPageShopOverlay flexColumn flexJustifyAndAlignCenter">
                      <h1>{shop.shop_name}</h1>
                      <div
                        className="flex"
                        style={{ gap: "10px", marginTop: "10px" }}
                      >
                        <div
                          className="landingPageShopRating"
                          style={{
                            backgroundColor:
                              shop.rating === null
                                ? "gray"
                                : shop.rating < 3
                                ? "#b90504"
                                : shop.rating < 4
                                ? "orange"
                                : "green",
                          }}
                        >
                          {shop.rating > 0 ? shop.rating.slice(0, 3) : " -- "}{" "}
                          &#9733;
                        </div>
                        <h3>
                          {shop.rating_count > 0 ? shop.rating_count : "No"}{" "}
                          Review
                          {shop.rating_count > 1 || shop.rating_count === "0"
                            ? "s"
                            : null}
                        </h3>
                      </div>
                      <p className="text3Lines">{shop.shop_address}</p>
                    </div>
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        <div
          className="flex flexJustifyAndAlignCenter"
          style={{
            backgroundColor: "#403d3d",
            width: "95%",
            maxWidth: "600px",
            height: "400px",
            position: "relative",
            left: "50%",
            transform: "translate(-50%, 0)",
            margin: "40px 0px",
            borderRadius: "30px",
          }}
        >
          <h1
            style={{
              color: "white",
              fontFamily: "montserrat",
              fontSize: "35px",
              textAlign: "center",
            }}
          >
            Be the first to join <br />
            in your area
          </h1>
        </div>
      )}
    </section>
  );
}

export default Details;
