import React from "react";

function Completed({ completedOrders = [] }) {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  return (
    <div className="ordersContainer flex">
      {completedOrders.map((order) => {
        return (
          <div className="orderCard" key={order.order_uid}>
            <div className="orderTag Active">Completed</div>
            <div className="ordersAddressDiv flex flexAlignCenter">
              <p className="text3Lines">
                <span>{order.user_full_name}</span>, {order.user_address}
              </p>
            </div>
            <div className="ordersOrderedProductsDiv flexColumn">
              {order.order_items.map((product) => {
                return (
                  <div
                    className="ordersItemDiv flex"
                    key={product.order_item_uid}
                  >
                    <h2>{product.product_name}</h2>
                    <h3>
                      {formatter.format(product.product_price)}
                      <br /> &times; {product.product_count}
                    </h3>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Completed;
