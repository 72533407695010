import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import "./styles.css";
import { axiosBusiness } from "../../axiosInstance.js";
import { LoadingContext } from "../../Context.js";

function InventoryEditPage() {
  const history = useHistory();
  const { id } = useParams();
  const [error, setError] = useState();
  const [availability, setAvailability] = useState(true);
  const [product, setProduct] = useState({});
  const { setLoading } = useContext(LoadingContext);

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const deleteHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosBusiness
      .delete(`/business/inventory/${id}`)
      .then((res) => {
        if (res.data.status === "success") {
          setLoading(false);
          history.push("/business/inventory");
        }
        if (
          res.data.status === "failed" &&
          res.data.message === "unauthorized"
        ) {
          history.push("/business/register");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        history.push("/business/inventory");
        setLoading(false);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (e.target.price.value.length > 0) {
      setLoading(true);
      const productData = {
        shop_uid: product.shop_uid,
        price: e.target.price.value,
        available: availability,
        mrp: product.product_mrp,
      };
      axiosBusiness
        .patch(`/business/inventory/${id}`, productData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setLoading(false);
            history.push("/business/inventory");
          } else if (
            res.data.status === "failed" &&
            res.data.message === "unauthorized"
          ) {
            history.push("/business/register");
            setLoading(false);
          } else if (
            res.data.status === "failed" &&
            res.data.message === "Above MRP"
          ) {
            setError("Price set greater than MRP");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
          history.push("/business/inventory");
          setLoading(false);
        });
    } else {
      setError("Price Not Set");
    }
  };

  const getProduct = () => {
    setLoading(true);
    axiosBusiness
      .get(`/business/inventory/${id}`)
      .then((res) => {
        if (res.data.status === "success") {
          setProduct(res.data.product);
          setAvailability(res.data.product.is_available);
          setLoading(false);
        }
        if (
          res.data.status === "failed" &&
          res.data.message === "unauthorized"
        ) {
          setLoading(false);
          history.push("/business/inventory");
        }
      })
      .catch((err) => {
        setLoading(false);
        history.push("/business/inventory");
      });
  };

  const changeAvailablity = () => {
    if (availability === true) {
      setAvailability(false);
    } else {
      setAvailability(true);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    if (product.product_price) {
      document.getElementById("price").value = product.product_price;
    }
  }, [product]);

  useEffect(() => {
    if (availability === false) {
      document.querySelector("#isAvailableButton").style.backgroundColor =
        "#b90504";
    } else {
      document.querySelector("#isAvailableButton").style.backgroundColor =
        "green";
    }
  }, [availability]);

  return (
    <React.Fragment>
      {error ? (
        <div
          className="flashCardError"
          style={{
            marginTop: "150px",
            width: window.outerWidth < 480 ? "95%" : "400px",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%,0)",
          }}
        >
          {error}
        </div>
      ) : null}
      <div id="editProductDiv">
        <form id="editProductForm" onSubmit={submitHandler}>
          <div style={{ width: "100%" }}>
            <h5>MRP: {formatter.format(product.product_mrp)}</h5>
            <p>*cannot set price above MRP*</p>
            <input
              name="price"
              type="number"
              placeholder="Price"
              id="price"
            ></input>
          </div>
          <button
            type="button"
            id="isAvailableButton"
            onClick={() => changeAvailablity()}
          >
            {availability === true ? "Available" : "Not Available"}
          </button>
          <div id="submitAndUpdateEditPageDiv">
            <button type="submit" className="redButton">
              Update
            </button>
            <button className="redButton" onClick={deleteHandler}>
              Delete
            </button>
            <button
              className="redButton"
              type="button"
              onClick={() => {
                history.push("/business/inventory");
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

export default InventoryEditPage;
