import React, { useRef, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { axiosBusiness } from "../../axiosInstance";
import { ShopDetailsContext } from "../../Context";

function MobileMenu({ setDropDown }) {
  const history = useHistory();
  const { setShopDetails } = useContext(ShopDetailsContext);
  const menu = useRef();
  const overlay = useRef();

  const logOutHandler = () => {
    axiosBusiness.get("/auth/business/logout").then((res) => {
      if (res.data.status === "success") {
        setShopDetails();
        console.log("logged out");
        history.push("/business/login");
      }
    });
  };

  return (
    <div
      className="overlayDiv flex"
      style={{ touchAction: "none", animationName: "overlayFadeIn" }}
      ref={overlay}
    >
      <div
        style={{ width: "100%", height: "100%" }}
        onClick={() => {
          menu.current.style.animationName = "menuSlideOutLeft";
          overlay.current.style.animationName = "overlayFadeOut";
          setTimeout(() => {
            setDropDown("");
          }, 480);
        }}
      ></div>
      <div className="mobileMenuDiv flexColumn" ref={menu}>
        <div className="mobileMenuLogoDiv flexColumn flexJustifyAndAlignCenter">
          <h1>Locaro</h1>
        </div>
        <div className="mobileMenuSections">
          <Link
            to="/business/dashboard"
            className="mobileMenuShopTabs textDecorNone flex flexAlignCenter"
          >
            Dashboard
          </Link>
          <Link
            to="/business/inventory"
            className="mobileMenuShopTabs textDecorNone flex flexAlignCenter"
          >
            Inventory
          </Link>
          <Link
            to="/business/orders"
            className="mobileMenuShopTabs textDecorNone flex flexAlignCenter"
          >
            Orders
          </Link>
        </div>
        <div className="mobileMenuSections">
          <h1 className="mobileMenuSectionHeader">Account</h1>
          <Link
            to="/"
            className="mobileMenuOptions textDecorNone flex flexAlignCenter"
          >
            Edit Profile
          </Link>
          <div
            className="mobileMenuOptions textDecorNone flex flexAlignCenter"
            onClick={logOutHandler}
          >
            Sign Out
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
