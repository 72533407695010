import React, { useState } from "react";
import ReactMaps, { GeolocateControl, Marker } from "react-map-gl";

import ComponentLoading from "../../components/componentLoading";

import locationPin from "./assets/locationPin.png";
import mapboxgl from "mapbox-gl";
import { axiosBusiness } from "../../axiosInstance";
import { useHistory } from "react-router-dom";

/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
/* eslint-enable import/no-webpack-loader-syntax, import/no-unresolved */

function Address({
  error,
  setError,
  setCompletedSteps,
  setCurrentStep,
  setCurrentSubmitData,
  currentSubmitData,
}) {
  const [componentLoading, setComponentLoading] = useState(false);
  const [locationText, setLocationText] = useState("");
  const [locationCoords, setLocationCoords] = useState({});
  const history = useHistory();

  const [viewport, setViewport] = useState({
    latitude: 19.0701,
    longitude: 72.8446,
    zoom: 10,
    width: "100%",
    aspectRatio: "16/9",
  });

  const geolocateControlStyle = {
    right: 10,
    top: 10,
  };

  const getLocation = () => {
    setComponentLoading(true);
    axiosBusiness
      .post("map/getlocation", {
        location: {
          longitude: viewport.longitude,
          latitude: viewport.latitude,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setLocationText(res.data.location);
          setLocationCoords({
            longitude: viewport.longitude,
            latitude: viewport.latitude,
          });
        }
        setComponentLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setComponentLoading(false);
      });
  };

  const submitHandler = (e) => {
    setComponentLoading(true);
    e.preventDefault();

    const submitData = {
      ...currentSubmitData,
      shop_location: locationCoords,
      shop_location_text: locationText,
      shop_address: e.target.shopAddress.value,
      shop_pincode: e.target.shopPincode.value,
    };

    axiosBusiness
      .post("/auth/business/register", submitData)
      .then((res) => {
        console.log(res.data);
        setComponentLoading(false);
        if (res.data.status === "success") {
          history.push("/business/inventory");
        }
      })
      .catch((err) => {
        setComponentLoading(false);
        console.log(err);
      });
  };

  return (
    <form
      className="businessRegisterPageMapForm flexColumn flexAlignCenter"
      onSubmit={submitHandler}
    >
      <div className="businessRegisterPageMapDiv">
        <ReactMaps
          {...viewport}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          onViewportChange={(viewport) => {
            setViewport(viewport);
          }}
        >
          <GeolocateControl
            style={geolocateControlStyle}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
            auto
          />
          <Marker
            latitude={viewport.latitude}
            longitude={viewport.longitude}
            offsetLeft={-16}
            offsetTop={-50}
          >
            <img src={locationPin} alt="" width="32px" height="50px" />
          </Marker>
          <button
            type="button"
            className="businessRegisterPageSetLocation redButton"
            onClick={getLocation}
          >
            Set Location
          </button>
        </ReactMaps>
      </div>
      <input
        type="text"
        placeholder="Location"
        className="businessRegisterPageDisabledInput"
        value={locationText}
        disabled
        style={{ marginTop: "10px" }}
      />
      <div className="animatedInputDivs">
        <input
          type="text"
          name="shopAddress"
          id="userAddressInput"
          placeholder=" "
          autoComplete="off"
          className="animatedInputInputs"
          required
        ></input>
        <label
          htmlFor="userAddressInput"
          className="animatedInputLabelsSmallerLighterGray"
        >
          Flat no / Building Name / Street name
        </label>
      </div>
      <div className="animatedInputDivs">
        <input
          type="text"
          name="shopPincode"
          id="userPinCodeInput"
          placeholder=" "
          autoComplete="off"
          className="animatedInputInputs"
          required
        ></input>
        <label
          htmlFor="userPinCodeInput"
          className="animatedInputLabelsLighterGray"
        >
          Pincode
        </label>
      </div>
      <button type="submit" className="redButton">
        Verify & Continue
        {componentLoading ? <ComponentLoading minHeight={"100%"} /> : null}
      </button>
    </form>
  );
}

export default Address;
