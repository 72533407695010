import React, { useContext, useEffect, useRef, useState } from "react";
import { axiosBusiness } from "../../axiosInstance";
import { LoadingContext, ShopDetailsContext } from "../../Context";

import "./styles.css";

function ShopInfoCard() {
  const { setLoading } = useContext(LoadingContext);
  const { shopDetails } = useContext(ShopDetailsContext);
  const form = useRef();
  const shopImageInput = useRef();
  const [imageURL, setImageURL] = useState(
    `url(${
      process.env.NODE_ENV === "production"
        ? "https://business.locaro.in/api"
        : "http://localhost:5000/api"
    }/images/shop_placeholder.jpg)`
  );

  const onChangeHandler = () => {
    setLoading(true);
    const fd = new FormData();
    fd.append("shop_image", shopImageInput.current.files[0]);
    axiosBusiness
      .post("/business/images/add", fd, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          window.location.reload();
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (shopDetails && shopDetails.shop_image) {
      setImageURL(
        `url(${
          process.env.NODE_ENV === "production"
            ? "https://business.locaro.in/api"
            : "http://localhost:5000/api"
        }/images/${shopDetails.shop_image})`
      );
    }
  });

  if (shopDetails) {
    return (
      <div className="infoCardParentDiv flexColumn flexJustifyAndAlignCenter">
        <div className="infoCard card-boxshadow flexColumn">
          <div
            className="shopInfoImageDiv"
            style={{
              backgroundImage: imageURL,
            }}
          >
            <div className="shopImageOverlayDiv flex">
              <div
                className="shopRatingDiv"
                style={{
                  backgroundColor:
                    shopDetails.rating === null
                      ? "gray"
                      : shopDetails.rating < 3
                      ? "#b90504"
                      : shopDetails.rating < 4
                      ? "orange"
                      : "green",
                }}
              >
                {shopDetails.rating > 0
                  ? shopDetails.rating.slice(0, 3)
                  : " -- "}{" "}
                &#9733;
              </div>
              <h1>
                {shopDetails.rating_count > 0 ? shopDetails.rating_count : "No"}{" "}
                Review
                {shopDetails.rating_count > 1 ||
                shopDetails.rating_count === "0"
                  ? "s"
                  : null}
              </h1>
            </div>
          </div>
          <div className="shopInfoTextDiv flexColumn">
            <h1>{shopDetails ? shopDetails.shop_name : ""}</h1>
            <h2>
              {shopDetails
                ? shopDetails.shop_address + ", " + shopDetails.shop_pincode
                : null}
            </h2>
            <h2
              style={{ fontWeight: 700, marginTop: "75px", fontSize: "20px" }}
            >
              {shopDetails ? "+91 " + shopDetails.shop_phone_number : null}
            </h2>
          </div>
        </div>
        <form ref={form}>
          <label
            htmlFor="shopInfoImageUpdate"
            className="fileInputLabel flex flexJustifyAndAlignCenter"
          >
            <input
              type="file"
              name="shop_image"
              id="shopInfoImageUpdate"
              accept="image/jpeg"
              ref={shopImageInput}
              onChange={onChangeHandler}
            />
            Edit Shop Image
          </label>
        </form>
      </div>
    );
  } else return null;
}

export default ShopInfoCard;
